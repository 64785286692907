<template>
  <div>
    <HeaderPanel
      title="Branch Type Management"
      :filter="filter"
      placeholder="Search Name"
      :hasExport="false"
      :hasFilter="false"
      :hasDropdown="false"
      @search="getData"
      class="mb-2 mb-lg-3"
      routerPath="/branchType/detail/0"
    />
    <main>
      <div class="bg-white border-red p-2 p-lg-3 mt-3">
        <b-row>
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(receipt_no)="{ item }">
                <span>{{ item.receipt_no }}</span>
              </template>
              <template v-slot:cell(void_no)="{ item }">
                <span>{{ item.void_no }}</span>
              </template>
              <template v-slot:cell(return_no)="{ item }">
                <span>{{ item.return_no }}</span>
              </template>
              <template v-slot:cell(is_fullpos)="{ item }">
                <span>{{ item.is_fullpos == "0" ? "No" : "Yes" }}</span>
              </template>
              <template v-slot:cell(actions)="{ item }">
                <div class="d-flex justify-content-evenly">
                  <router-link :to="'branchType/detail/' + item.branch_type_id">
                    <b-button variant="link" class="text-warning px-1 py-0">
                      <font-awesome-icon icon="pencil-alt" title="Edit" />
                    </b-button>
                  </router-link>
                </div>
              </template>

              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <Pagination
          @handleChangeTake="handleChangeTake"
          :filter="filter"
          :rows="rows"
          @pagination="pagination"
        />
      </div>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filter: {
        page: 1,
        take: 10,
        search: "",
      },
      rows: 0,
      fields: [
        {
          label: "Name",
          key: "name",
        },
        {
          label: "Receipt No.",
          key: "receipt_no",
        },
        {
          label: "Void No.",
          key: "void_no",
        },
        {
          label: "Return No.",
          key: "return_no",
        },
        {
          label: "Digit Of Running No.",
          key: "digit_running_no",
        },
        {
          label: "Full Function POS",
          key: "is_fullpos",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      items: [],
      isBusy: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    handleChangeTake(vale) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getData();
    },
    pagination(page) {
      this.filter.page = page;
      this.getData();
    },
    async getData() {
      this.isBusy = true;
      const res = await this.$Axios.post(
        `${process.env.VUE_APP_API}/branch/GetBranchTypeList`,
        this.filter
      );

      this.rows = res.data.detail.count;
      this.items = res.data.detail.data;
      this.isBusy = false;
    },
  },
};
</script>

<style></style>
